import Logo from './../img/logosmallblanco.png'
import { Link, NavLink } from 'react-router-dom';
import { Background } from 'react-parallax';

export default function Menu() {

  const claseActiva = "active";

  return (
    <div className=''>



      <nav className="navbar navbar-expand-lg navbar-light static-top" style={{ marginTop: '10px', backgroundColor: '#c1d3e9' }} >
        <div className="container">



          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>



          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ fontSize: '18px', fontWeight: '500' }} >

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link " } to="/"   >Inicio</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link " } to="/nosotros"  >Nosotros</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link " } to="/cursos"  >Cursos</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link " } to="/contactenos"  >Contactenos</NavLink>
              </li>


              <li className="nav-item">
                <NavLink className={(navData) => navData.isActive ? "nav-link  active" : "nav-link " } to="/denuncia"   >Denuncia</NavLink>
              </li>






              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="/legal" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Legal
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><NavLink  to="/certificacion" className={(navData) => navData.isActive ? "dropdown-item active" : "dropdown-item" }  >CERTIFICACIÓN NORMA NTC 6072 POR CELAC</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className={(navData) => navData.isActive ? "dropdown-item active" : "dropdown-item" }  to="/autorizacion" >AUTORIZACIÓN MINTRABAJO</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className={(navData) => navData.isActive ? "dropdown-item active" : "dropdown-item" }  to="/inscripcion"  >INSCRIPCIÓN MINTRABAJO</NavLink></li>
                </ul>

              </li>




            </ul>
          </div>


        </div>
      </nav>
    </div>


  )

}