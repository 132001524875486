
import { Parallax } from 'react-parallax'
import Imagen from './../img/parallax.png'

import CSS from './../css/parallax.module.css'

export default function ParallaxUno(){

  
    return(
    <div className=''>
        <Parallax bgImage={Imagen} strength={200}>
            <div style={{height:'300px'}}>
                <div className={CSS.insideStyle}>
                    <h2 style={{color:'#000',textAlign:'center'}}>HSEQ INTERNATIONAL S.A.S.</h2>
                    <p style={{color:'#000',fontSize:'20px',textAlign:'center'}}></p>
                </div>
            </div>
        </Parallax>
    </div>
        )
}