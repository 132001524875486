import Logo from './../img/consulta.png'



export default function SiderA(){
    return(
        <div  className="me-12 d- mt-4 text-center"  >
          <h4>HSEQ INTERNATIONAL SAS</h4>
          <p>
          Se dedica a la Asesoría, consultoría y prestación de servicios en salud ocupacional, contamos con licencia de Salud Ocupacional No.1499 del 05 de Marzo del 2013.
          </p>
          
          <p >
          <a href='http://www.consultahseq.appcertificados.com/' target={'_blank'}>
          <img className='d-block w-100' src={Logo} alt='Mensajero' />          
          </a>
          
          </p>
          
        </div>
        
    )
}