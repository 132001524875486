

export default function FormularioContacto() {
    return (
        <div className="row">
            <div className='col-12 col-sm-4'>
                <img className="card-img-top" src={require('./../img/contacto.jpg')} alt="Card image cap" />
            </div>
            <div className='col-12 col-sm-8 p-4 '>
                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-home me-3"></i>CALLE 34 No.41-64 BARZAL ALTO</p>
                <p><i className="fas fa-home me-3"></i>CENTRO DE FORMACION EN LA CALLE 1 LOTE 2 KM 1 LLANO LINDO</p>
                <p><i className="fas fa-envelope me-3"></i> hseqinternational@ gmail.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 311 2241304</p>
            </div>
        </div>
    )
}

interface formularioContactoProps {
    celular: string;
    correo: string;
    direccion: string;
}