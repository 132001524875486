import celac from './../img/celac.jpg'
export default function Certificacion() {

    return (
        <div className='row'>
        <div className='col-12 col-sm-8 mx-auto'>
            <img className='w-100' src={celac}></img>
        </div>
        </div>
    )

}