
import CardHorizontal from './CardHorizontal';

export default function NuestrosServicios(){


    const items = [
        { id: 1, folder: 'cursos', imagen: 'alturas.jpg', titulo: 'TRABAJO EN ALTURAS', contenido: 'Trabajo en altura es aquel que se realiza en cualquier lugar donde, si no se han adoptado las precauciones necesarias, una persona puede caer desde una altura que puede provocar lesiones (una caída a través de un tejado frágil, por un foso de ascensor sin protección, por el hueco de una escalera).' },
        { id: 2, folder: 'cursos', imagen: 'primerosauxilios.jpg', titulo: 'PRIMEROS AUXILIOS', contenido: 'Se entiende por primeros auxilios, los cuidados inmediatos, adecuados y provisionales prestados a las personas accidentadas o con enfermedades de aparición súbita antes de ser antentidos en un centro asistencial' },
        { id: 3, folder: 'cursos', imagen: 'manejodefensivo.jpg', titulo: 'MANEJO DEFENSIVO', contenido: 'Manejar defensivamente implica mantener un espacio de seguridad entre nuestro vehiculo y los demás usuarios viales, hacer que los demás conductores noten la presencia de nuestro vehiculo y tener el tiempo suficiente para tomar desiciones.' },
        { id: 4, folder: 'cursos', imagen: 'espaciosconfinados.jpg', titulo: 'ESPACIO CONFINADO', contenido: 'Un espacio confinado, es todo ambiente que tiene medios limitados para entrar y salir, es decir no permiten una entrada ni una salida en forma segura y rápida de todos sos ocupantes.' },
    ];


return(
    <div className="w-100 pt-2 text-center">
    <h3 className='p-3 fs-3 border-top border-3 '>
        Nuestros Cursos
    </h3>
    <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>
</div>
)


}