export default function NosotrosContent() {

    return (
        <div className="w-75 mx-auto pt-5" style={{textAlign:'justify'}}>
            <h4><span ><strong>HSEQ INTERNATIONAL S.A.S. </strong></span></h4>
            <p  >Se dedica a la  Asesoría, consultoría y  prestación de  servicios en salud ocupacional, contamos  con licencia  de Salud  Ocupacional No.1499 del 05 de Marzo  del 2013. Expedida  por  la  secretaria  de  salud  del  Meta e igualmente le  suministramos  todo  tipo  de   elemento de  protección   industrial.</p>
            <hr />
            <h4><span ><strong>MISION</strong></span></h4>
            <p >HSEQ INTERNATIONAL S.A.S es una entidad que se encarga de prestar servicios en las áreas  de la  Salud Ocupacional principalmente a los siguientes sectores: petrolero, químico, petroquímico, industrial, agropecuario, turístico y ambiental; igualmente ofrece  asesoramiento, documentación e implementación  de  sistemas de gestión integral, Reclutamiento de Personal Calificado, Seguridad Industrial y Responsabilidad Social.</p>
            <hr />
            <h4><span ><strong>VISION </strong></span></h4>
            <p >HSEQ INTERNATIONAL S.A.S para el 2015, se reconocerá por ser una empresa líder a nivel regional y participando en el mercado nacional prestando servicios en Salud Ocupacional, contando con un grupo Interdisciplinario  de profesionales especializados y capacitados, además con la infraestructura, equipos y herramientas  a la vanguardia, con  el  fin  de  prestar un optimo servicio.</p>
            <hr />
            <h4><span ><strong>OBJETIVO   GENERAL</strong></span></h4>
            <p >Prestar servicios de asesoría al sector empresarial en salud ocupacional,  cumpliendo  con las tres grandes  subprogramas  de la  salud  ocupacional (seguridad  industrial, higiene  industrial,  y medicina ocupacional)  basándonos  en   indicadores de gestión,   realizando  los respectivos  matriz  de  riesgos  y planes  de  emergencias.</p>

        </div>
    )
}