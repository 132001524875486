import denuncia_1 from './../img/denuncia_1.jpg'
import denuncia_2 from './../img/denuncia_2.jpg'
export default function Deenuncia() {

    return (
        <div className='row'>
        <div className='col-12 col-lg-6 '>
            <img className='w-100' src={denuncia_1}></img>
        </div>
            
        <div className='col-12 col-lg-6 '>
            <img className='w-100' src={denuncia_2}></img>
        </div>
            
        </div>
    )

}