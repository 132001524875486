import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Menu from './components/modules/Menu';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Nosotros from './components/pages/Nosotros';
import Contactenos from './components/pages/Contactenos';
import { useEffect } from 'react';
import Footer from './components/modules/Footer';
import FormHeader from './components/modules/FormHeader';
import Cursos from './components/pages/Cursos';
import Denuncia from './components/pages/Denuncia';
import Certificacion from './components/pages/Certificacion';
import Inscripcion from './components/pages/Inscripcion';
import Autorizacion from './components/pages/Autorizacion';

function App() {

  
  useEffect(()=>{
    
    document.title = "HSEQ-R";
    //document.body.style.backgroundImage=Fondo;
    });


  return (
    <div className="container bg-light"  >
      <FormHeader></FormHeader>
      
      
      <BrowserRouter>      
      <Menu></Menu>
      <Routes>
        <Route  path="/nosotros" element={<Nosotros/>} ></Route>
        <Route  path="/cursos" element={<Cursos/>} ></Route>
        <Route  path="/contactenos" element={<Contactenos/>} ></Route>
        <Route  path="/denuncia" element={<Denuncia/>} ></Route>
        <Route  path="/certificacion" element={<Certificacion/>} ></Route>
        <Route  path="/autorizacion" element={<Autorizacion/>} ></Route>
        <Route  path="/inscripcion" element={<Inscripcion/>} ></Route>
        <Route  path="/" element={<Home/>}></Route>
        <Route  path="*" element={<Home/>}></Route>
      </Routes>      
      </BrowserRouter>      
      <Footer></Footer>
    </div>
  );
}

export default App;
