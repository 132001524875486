export default function DescripcionSitio() {
    return (
        <section className="pt-2">
            <div className="container-fluid pt-10 text-center">
                <h4>HSEQ INTERNATIONAL SAS</h4>
                <p>
                    Se dedica a la Asesoría, consultoría y prestación de servicios en salud ocupacional, contamos con licencia de Salud Ocupacional No.1499 del 05 de Marzo del 2013.
                </p>
            </div>
        </section>
    )
}