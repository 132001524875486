

import NuestrosServicios from '../modules/NuestrosServicios';
export default function Cursos() {

    return (
        <div>
            <NuestrosServicios></NuestrosServicios>
        </div>
    )

}