export default function Footer() {

  return (
    <div className=" mb-4">

      <footer className="text-center text-lg-start text-muted" style={{ backgroundColor: '#c1d3e9' }}>

        <section className="d-flex justify-content-center  p-4 border-bottom">


          <div className="me-5 d-none d-lg-block">
            <span>Siguenos en nuestras redes sociales:</span>
          </div>

          <div>
            <a href="https://www.facebook.com/hseqinternationalsas" target="_blank" className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/hseq_international/" target="_blank" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
          </div>

        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">

              <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>HSEQ INTERNATIONAL SAS
                </h6>
                <p>
                  Se dedica a la  Asesoría, consultoría y  prestación de  servicios en salud ocupacional, contamos  con licencia  de Salud  Ocupacional No.1499 del 05 de Marzo  del 2013.
                </p>
              </div>




              <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-md-0 mb-4" style={{ textAlign: 'center' }} >

                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-home me-3"></i>CALLE 34 No.41-64 BARZAL ALTO</p>
                <p><i className="fas fa-home me-3"></i>CENTRO DE FORMACION EN LA CALLE 1 LOTE 2 KM 1 LLANO LINDO</p>
                <p><i className="fas fa-envelope me-3"></i> hseqinternational@ gmail.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 311 2241304</p>
              </div>

            </div>

          </div>
        </section>



        <div className="text-center pt-4 pb-4 " style={{ backgroundColor: '#015e9c', color: '#FFF' }}>
          <p>
            © 2022 Copyright: <a style={{ textDecoration: 'none' }} className="text-reset fw-bold" href="https://multinet.com.co/">  Multinet Soluciones Digitales</a>
          </p>
        </div>
      </footer>
    </div>


  )


}