import Alturas from './../img/cursos/alturas.jpg'
import PrimerosAuxilios from  './../img/cursos/primerosauxilios.jpg'
import Manejo from './../img/cursos/manejodefensivo.jpg'
import Espacios from  './../img/cursos/espaciosconfinados.jpg'


export default function Servicios() {
    return (
        <div className='row'>
            <section className="container-fluid mx-auto text-center pt-2">


                <div>
                    <h3 className='p-3 fs-3 border-top border-3 '>
                        Nuestros Cursos
                    </h3>
                </div>


                <div className='row w-100 mx-auto'>

                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Alturas} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Trabajo en alturas</h5>
                        <p className='px-4 text-center'></p>
                    </div>


                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={PrimerosAuxilios} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Primeros Auxilios</h5>
                        <p className='px-4 text-center'></p>
                    </div>

                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Manejo} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Manejo defensivo</h5>
                        <p className='px-4 text-center'></p>
                    </div>

                    <div className='col-12 col-md-3'>
                        <img className='d-block w-100' src={Espacios} alt='hseq' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Espacios confinados</h5>
                        <p className='px-4 text-center'></p>
                    </div>

                </div>
            </section>
        </div>

    )
}